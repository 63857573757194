/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"kontakt"}>
        <SiteHeader />

        <Column className="css-1m0wa9y --full pb--60 pt--60" name={"4m7lu7vev5b"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/28f52e328d414c23a0429b8860c113dd_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"font-weight: bold; color: rgb(253, 2, 2);\">Kontakt</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"rgba(97,70,55,1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"font-weight: bold; color: rgb(244, 6, 6);\">Marcela a Zdeněk Palíškovi</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Mobil:&nbsp; &nbsp; &nbsp; &nbsp;Marcela&nbsp; +420 737 391 008<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Zdeněk&nbsp; &nbsp; +420 776 352 383<br>E-mail:&nbsp; &nbsp; &nbsp; paliskova.keramika@seznam<br>FB:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;facebook.com/zpalisek<br>Bydliště a dílna:&nbsp; &nbsp; &nbsp; Křenůvky č.10<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 798 04&nbsp; &nbsp; Určice<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; okres Prostějov<br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Česká republika<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":495}} content={"<span style=\"font-weight: bold; color: rgb(252, 5, 5);\">Naše výrobky můžete koupit přímo u nás, <br>kde máme malou prodejní galerii. Je možné přijet kdykoli po telefonické domluvě.&nbsp;&nbsp;</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\"><br><br><br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rev14jd4uw"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(253, 13, 5);\">Marcela&nbsp;</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold; color: var(--black);\">Já jsem ta, co přišla s nápadem dělat keramiku.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/c1bb32f4ddf74204b3be03c234fe6c2b_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/c1bb32f4ddf74204b3be03c234fe6c2b_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/c9748b440be641af8806a6fa466cf649_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/c9748b440be641af8806a6fa466cf649_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/c9748b440be641af8806a6fa466cf649_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/c9748b440be641af8806a6fa466cf649_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19002/c9748b440be641af8806a6fa466cf649_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19002/c9748b440be641af8806a6fa466cf649_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(252, 11, 3);\">Zdenek z Křenůvek</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold; color: var(--black);\">Když jsem se rozhodl pověsit svoje původní řemeslo na hřebík, přišlo rozhodnutí se věnovat keramice také</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mmfxlrlz89"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(252, 3, 3);\">Náš pes Dasty</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold; color: var(--black);\">Když k nám přijdete nakoupit, budu první kdo Vás přivítá. Já jsem šéf prodeje.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/f0434a3c97e0443abd1c782c475cddec_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/f0434a3c97e0443abd1c782c475cddec_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/f0434a3c97e0443abd1c782c475cddec_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/f0434a3c97e0443abd1c782c475cddec_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19002/f0434a3c97e0443abd1c782c475cddec_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19002/f0434a3c97e0443abd1c782c475cddec_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/07270d297c05488f9bd22d8362c82206_s=860x_r=90_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/07270d297c05488f9bd22d8362c82206_s=350x_r=90_.jpg 350w, https://cdn.swbpg.com/t/19002/07270d297c05488f9bd22d8362c82206_s=660x_r=90_.jpg 660w, https://cdn.swbpg.com/t/19002/07270d297c05488f9bd22d8362c82206_s=860x_r=90_.jpg 860w, https://cdn.swbpg.com/t/19002/07270d297c05488f9bd22d8362c82206_s=1400x_r=90_.jpg 1400w, https://cdn.swbpg.com/t/19002/07270d297c05488f9bd22d8362c82206_s=2000x_r=90_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(254, 2, 2);\">Kocour Dorián</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold; color: var(--black);\">Za kvalitu keramických výrobků jsem zodpovědný jááááá</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fbrznuaubtr"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(251, 2, 2);\">Prodejní stánek</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold; color: var(--black);\">I toto je jeden z kontaktů jak nás najít na jarmarku.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/4476855e41d3478faf643eada9e6c09c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/4476855e41d3478faf643eada9e6c09c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/4476855e41d3478faf643eada9e6c09c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/4476855e41d3478faf643eada9e6c09c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/d902c0faec9643bc87137366d45dfce8_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/d902c0faec9643bc87137366d45dfce8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/d902c0faec9643bc87137366d45dfce8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/d902c0faec9643bc87137366d45dfce8_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(255, 4, 4);\">Naše galerie</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold; color: var(--black);\">V těchto prostorách na Vás čekáme. Keramika od Palíšků</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-qsl24o pb--60 pt--60" name={"l8lca1wp37p"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19002/4459c41f50614971a1c3e115c0d92821_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}